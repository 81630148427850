.timeline {
  position: absolute;
  left: calc(50% - 425px);
  bottom: 67px;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  width: 850px;
  z-index: 2;
  // border-bottom: 4px solid;
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4px;
   // background-image: linear-gradient(to right,rgba(69, 170, 232, 0),#45AAE8 50%, rgba(69, 170, 232, 0));
  }
  :global {
    .ant-picker {
      align-self: flex-end;
      // margin-bottom: 12px;
      padding: 0 10px;
      background: rgba(163, 223, 255, 0.33);
      border: none;
      border-radius: 0;
      box-shadow: none;
      height: 18px;
      .ant-picker-input {
        width: 100px;
        input {
          color: #fff;
        }
        svg {
          color: #4CD4FF;
        }
      }
    }
    .ant-slider {
      margin-left: 15px;
      margin-bottom: 0;
      padding: 0 18px;
      width: 705px;
      height: 16px;
      // .ant-slider-mark {
      //   display: flex;
      //   justify-content: space-between;
      // }
      .ant-slider-rail, .ant-slider-track {
        height: 16px;
        border-radius: 2px;
      }
      .ant-slider-rail {
        background: rgba(163, 223, 255, 0.2);
      }
      .ant-slider-track {
        background: #1EC2F5;
      }
      .ant-slider-dot {
        display: none;
      }
      .ant-slider-mark {
        transform: translateY(-35px);
        top:32px;
        left: 1%;
      }
      .ant-slider-handle {
        margin-top: -12px;
        width: 40px;
        height: 40px;
        background-color: transparent;
        background-image: url(../../assets/img/bg_timeline_handle.png);
        background-size: cover;
        border: none;
        border-radius: 0;
        box-shadow: none;
      }
    }
  }
}
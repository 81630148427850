.login_contenter{
    width: 100%;
    height: 100vh;
    min-height:850px ;
    background: url(../../assets/img/login-bg.png) no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    .login_box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1440px;
        margin: auto;
    }
    .login_name{
        width: 700px;
        position: relative;
        p{
         font-size: 68px;
         color: #40F7FF;
         text-align: center;
         font-weight: bold;
        }
        .login_tag{
            background: url(../../assets/img/login-tag.png) no-repeat;
            background-size: cover;
            width: 1000px;
            height: 180px;
            z-index: 0;
            position: absolute;
            left: -150px;
            span{
            font-size: 30px;
            color: #fff;
            position: absolute;
            left: 320px;
            bottom: 48px

            }
        }
    }
    .login_main{
    width: 700px;
    height: 850px;
    background: url(../../assets/img/login-box.png) no-repeat;
    background-size: cover;
    position: relative;

    }
    
}

:global {
    form{
        position: absolute;
        top:calc(50% - 160px);
        width: 476px;
        height: 400px;
        left: 64px;
    }
    .ant-row{
        border-bottom:1px solid #2FF6FF ;
        height: 64px;
    }
    .ant-form-item-label > label{
        color: #fff;
        font-size: 20px;
        line-height: 64px;
        margin-right: 15px;
    }
    .ant-form-item-label > label::after{
        display: none;
    }
    .ant-input-affix-wrapper > input.ant-input{
        &:focus{   
            border:none;
            outline: none;
            background-color: transparent;
  
        }
    }
    .ant-input,.ant-input-affix-wrapper{
        background-color: transparent;
        border: 0;
        outline: none;
        color: #fff;
        font-size: 16px;
        
    }
    .verifyinput{
        .ant-form-item-control-input-content{
            display: flex;
        }
        .ant-input{
            width: 316px;
            &:focus{   
                border:none;
                outline: none;
                background-color: transparent;
      
            }
        }
    }
    
    .loginbnt{
        width: 476px;
        height: 61px;
        background: linear-gradient(270deg, #21FFED, #0AB6FE, #495AFF);
        border-radius: 30px;
        color: #131954;
        line-height: 64px;
        font-size: 24px;
        margin-top: 46px;
        border: none;
        padding: 0;
        
      
        &:hover{
            background: linear-gradient(270deg, #21FFED, #0AB6FE, #495AFF);
        border-radius: 30px;
        color: #131954;
        }
    }
    .password{
        label{
            letter-spacing: 9px;
        }
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
        display: none;
    }
    .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper,
     .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover{
        background-color: transparent;
     }
     .ant-form-item-with-help{
         margin-bottom: 24px;
     }
     .ant-form-item-explain, .ant-form-item-extra{
         font-size: 12px;
     }
    
}

 .verifybox{

     position: relative;
     .verifyImg{
         position: absolute;
         right: 0;
         bottom: 26px;
     }
 }
 
body {
  color: #fff;
}
.app {
  position: relative;
  width: 100vw;
  min-width: 1920px;
  height: 100vh;
  /* min-height: 1080px; */
  background-color: #0A2A4D;
  overflow: hidden;
}
.app.app_sm {
  min-width: auto;
}
.app-wide {
  width: 3472px;
}

#thing-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.hide {
  display: none;
}

.shadow::after, .shadow::before {
  content: '';
  position: absolute;
  width: 670px;
  z-index: 0;
  height: 100%;
  display: block;
  pointer-events: none;
}
.shadow::before {
  left: 0;
  background: linear-gradient(to right,#031C36,rgb(3 28 54 / 92%), rgb(3 28 54 / 58%), rgba(10, 41, 76, 0));
}
.shadow::after {
  right: 0;
  background: linear-gradient(to left,#031C36,rgb(3 28 54 / 92%), rgb(3 28 54 / 58%), rgba(10, 41, 76, 0));
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.cursor-pointer{
  cursor: pointer;
  color: #fff;
  
}
.cursor-pointe :hover{
  transform: scale(1.1);
}

.alarm_close {
  margin: 11px;
  vertical-align: top;
  width: 14px;
  height: 14px;
  
}
.alarm_modal {
  top: calc(50% - 350x);
  right: 20px;
  position: absolute;
  :global {
    .ant-modal-close {
      top: -31px;
      // margin-top: 12px;
      // margin-right: 12px;
      img{
        display: none;
      }
    }
    .ant-modal-close-x {
      width: 64px;
      height: 22px;
     .alarm_close{
       display: none;
     }
    }
    .ant-modal-content {
      padding: 13px 13px 14px 13px;
      height: 810px;
      background-color: transparent;
      background-color: rgba(17,71,98,0.9);
      border: 2px solid rgba(67, 167, 229, 0.86);
      .ant-modal-body {
        position: relative;
        height: 100%;
        padding: 0;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.monitor_btn{
 position:absolute;
 top: calc(50% - 400px);
 right: 20px;
 font-size: 16px;
 color: #fff;
 cursor: pointer;
}
.list{
  width: 100%;
  height: 100%;
  clear: both;
  .item{
    width: 100%;
    float: left;
    height: calc(25%);
    
  }
}
.data_box {
    // width: 100%;
    // height: 100%;
    // position: absolute;
    // top: 380px;
    // left: 480px;
    // display: flex;
    // align-items: center;
    // width: 260px;
    // flex-direction: column;
    // background: linear-gradient(to right, rgba(16, 54, 93, 0.2), rgba(10, 66, 100, 0.8) 50%, rgba(16, 54, 93, 0.2));
}

.data_box_item {
    position: relative;
    width: 260px;
    height: 116px;
    //background:linear-gradient(90deg, rgba(225,225,255,0),rgb(13 30 48/60%) 60%);
    background: url(~@/assets/img/cout-box-right.png) no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    .item_piot {
        position: absolute;
        //    top: 1px;
        //    right:-80px;
        //    width: 82px; 
        top: 0px;
        right: -80px;
        width: 80px;
        img {
            width: 100%;
        }
    }
    /*位置*/
    &:nth-of-type(1) {
        position: absolute;
        top: 32%;
        right: 580px;
        //background:linear-gradient(90deg,rgb(13 30 48/60%) 60% , rgba(225,225,255,0));
        // .item_content{
        //     margin-left: 280px;
        // }
        .item_piot {
            // top: 1px;
            // left:-190px;
            // width: 190px; 
            top: 35px;
            left: -92px;
            width: 130px;
            transform: rotate( 321deg);
        }
        .item_title {
            //margin-left: 280px;
            text-align: left;
            padding-right: 0;
            padding-left: 35px;
            //background: url(~@/assets/img/cout-box1-right.png) no-repeat;
            background-size: cover;
            background: linear-gradient(90deg, rgb(13 30 48/60%) 60%, rgba(225, 225, 255, 0));
        }
    }
    &:nth-of-type(2) {
        position: absolute;
        top: 50%;
        right: 580px;
        .item_piot {
            // top: 1px;
            // left:-80px;
            // transform: rotate(118deg);
            top: 8px;
            left: -75px;
            width: 80px;
            transform: rotate(107deg);
        }
        //background:linear-gradient(90deg,rgb(13 30 48/60%) 60% , rgba(225,225,255,0));
        // .item_content{
        //     margin-left: 280px;
        // }
        .item_title {
            //margin-left: 280px;
            text-align: left;
            padding-right: 0;
            padding-left: 30px;
            //background: url(~@/assets/img/cout-box1-right.png) no-repeat;
            background-size: cover;
            background: linear-gradient(90deg, rgb(13 30 48/60%) 60%, rgba(225, 225, 255, 0));
        }
    }
    &:nth-of-type(3) {
        position: absolute;
        top: 45%;
        left: 500px;
        background: url(~@/assets/img/cout-box.png)right no-repeat;
        background-size: cover;
    }
}

.item_content {
    width: 260px;
    height: 116px;
    padding: 6px 30px;
    line-height: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.item_title {
    height: 37px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    background: linear-gradient(90deg, rgba(225, 225, 255, 0), rgb(13 30 48/60%) 60%);
    //background: url(~@/assets/img/cout-box1.png) no-repeat;
    //background-size: cover;
    font-weight: bold;
    color: #D3A871;
    line-height: 37px;
    margin-top: 12px;
    width: 100%;
    position: absolute;
    top: -48px;
    text-align: right;
    padding-right: 30px;
    i {
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #D3A871;
        vertical-align: middle;
        margin-right: 10px;
    }
}

.item_box {
    min-width: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item_data_text {
    width: 88px;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
}

.data_tamt {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00FFFF;
    line-height: 26px;
    height: 26px;
}
.map_bar {
  position: absolute;
 // top: 216px;
  top: calc(50% - 252px);
  right: 500px;
  z-index: 2;

}
.map_bar_box{
  position: absolute;
  bottom: 28px;
  right: 86px;
  z-index: 2;
  animation-name: showMapBar;
  animation-duration: 0.05s;
  background-image: url(~@/assets/img/more_bg.png);
  background-size: 100% 100%;
  padding: 14px;
 // box-shadow: inset 0 0 5px 3px rgb(225 225 225);
  button {
    
  &:last-child{
    margin:0
  }
  }
}
.map_bar_main{
  button {
    display: block;
    margin-bottom: 20px;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    img {
      width: 59px;
      height: 58px;
    }
    img:hover{
      transform: scale(1.2);
      transition-duration: 300ms;
    }
  }
}

@keyframes showMapBar {
  0%   { width:0px;}
  100%  { width:60px;}
 
}
.street_list {
  position: absolute;
  // - 316 parentTop + 247 selfTop
  top: 0px;
  //500 parentRight + 132 selfWidth + 518 selfLeft
  right: calc(100vw - 1150px);
  width: 132px;
  height: 132px;
  background-size: cover;
  background-image: url(~@/assets/img/bg_street.png);
  p {
    text-align: center;
    &.street_list_number {
      margin-top: 28px;
      font-size: 38px;
      font-weight: bold;
      line-height: 53px;
      text-shadow: 0px 0px 6px rgba(228, 253, 255, 0.44);
    }
    &.street_list_text {
      margin-top: -4px;
    }
  }
}
.street_list_select {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 100px;
  opacity: 0;
  :global {
    .ant-select-selector {
      height: 100px !important;
    }
  }
}
.street_list_select_dropdown {
  :global {
    .ant-select-item-option {
      padding: 8px;
    }
  }
}
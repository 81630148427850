.search {
  position: absolute;
  top: 10px;
 right: 10px;
  display: flex;
  align-items: center;
  width: 300px;
  height: 48px;
  background: linear-gradient(to right, rgba(16, 54, 93, 0.2), rgba(10, 66, 100, 0.8) 50%, rgba(16, 54, 93, 0.2));
  border: 2px solid;
  border-image: linear-gradient(135deg, rgba(10, 66, 100, 1), rgba(10, 66, 100, 0), rgba(10, 66, 100, 0.76)) 2 2;
  z-index: 2;
  &.search_sm {
    top: 10px;
   right: 10px;
    width: 300px;
    height: 35px;
    background:rgba(13, 45, 75, 0.66);
    border: 1px solid #45AAE8;
    border-image: none;
    .search_divide {
      display: none;
    }
    :global {
      .ant-select {
        flex-shrink: 0;
        margin: 0;
        padding: 2px 0;
        width: 120px;
        background: #002E4C;
        border-right: 1px solid #45AAE8;
        .ant-select-selector {
          padding-left: 33px;
        }
        .ant-select-arrow {
          right: 25px;
        }
      }
    }
  }
  .search_select {
    margin-left: 10px;
    width: 90px;
    font-size: 16px;
  }
  .search_divide {
    margin-left: 14px;
    width: 2px;
    height: 24px;
    background-color: #2DABED;
  }
  .search_input {
    input {
      font-size: 16px;
      color: #fff;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      caret-color: #3788BA;
      &::placeholder {
        color: #B0B0B0;
      }
    }
    :global .ant-input-group-addon {
      padding-right: 8px !important;
      background: transparent;
      button {
        background: transparent;
        border: none;
        box-shadow: none;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}